.carousel {
  text-align: center;
  position: relative;
  opacity: 0;
  margin: 0 auto;
  transition: opacity 0.5s;
}
.carousel .carousel-container-inner {
  margin: 0 auto;
  position: relative;
}
.carousel .carousel-viewport {
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.carousel .carousel-arrow {
  position: absolute;
  z-index: 1;
  color: white;
  border: 3px solid;
  border-radius: 50%;
  bottom: 23px;
  height: 32px;
  width: 32px;
  font-weight: 900;
  background: rgba(0, 0, 0, 0.15);
  padding: 0;
  cursor: pointer;
}
.carousel .carousel-arrow:focus {
  outline: none;
}
.carousel .carousel-arrow:before {
  font-size: 19px;
  display: block;
  margin-top: -2px;
}
.carousel .carousel-arrow:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.carousel .carousel-left-arrow {
  left: 23px;
}
.carousel .carousel-left-arrow:before {
  content: '<';
  padding-right: 2px;
}
.carousel .carousel-right-arrow {
  right: 23px;
}
.carousel .carousel-right-arrow:before {
  content: '>';
  padding-left: 2px;
}
.carousel .carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  touch-action: pan-y pinch-zoom;
}
.carousel .carousel-track .carousel-slide {
  display: inline-block;
  opacity: 0.7;
  transition: opacity 0.5s ease-in-out;
  white-space: initial;
}
.carousel .carousel-track .carousel-slide > * {
  display: block;
}
.carousel .carousel-track .carousel-slide.carousel-slide-loading {
  background: rgba(204, 204, 204, 0.7);
}
.carousel .carousel-track .carousel-slide.carousel-slide-fade {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
.carousel .carousel-track .carousel-slide.carousel-slide-selected {
  opacity: 1;
  z-index: 1;
}
.carousel.loaded {
  opacity: 1;
}
.carousel .carousel-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center;
}
.carousel .carousel-dots li {
  display: inline-block;
}
.carousel .carousel-dots button {
  border: 0;
  background: transparent;
  font-size: 1.1em;
  cursor: pointer;
  color: #CCC;
  padding-left: 6px;
  padding-right: 6px;
}
.carousel .carousel-dots button.selected {
  color: black;
}
.carousel .carousel-dots button:focus {
  outline: none;
}
